// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-pages-index-js": () => import("/opt/build/repo/src/templates/pages/index.js" /* webpackChunkName: "component---src-templates-pages-index-js" */),
  "component---src-templates-pages-contact-js": () => import("/opt/build/repo/src/templates/pages/contact.js" /* webpackChunkName: "component---src-templates-pages-contact-js" */),
  "component---src-templates-pages-blogs-js": () => import("/opt/build/repo/src/templates/pages/blogs.js" /* webpackChunkName: "component---src-templates-pages-blogs-js" */),
  "component---src-templates-pages-thanks-js": () => import("/opt/build/repo/src/templates/pages/thanks.js" /* webpackChunkName: "component---src-templates-pages-thanks-js" */),
  "component---src-templates-post-jsx": () => import("/opt/build/repo/src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

